import React, { CSSProperties, useCallback, useEffect, useMemo, useState } from "react";

import { Checkbox, Dimmer, Icon, Loader, Modal } from "semantic-ui-react";

// Common
import ButtonLoadCheck from "react-lib/appcon/common/ButtonLoadCheck";
import DropdownOptions from "react-lib/appcon/common/DropdownOptions";
import ModConfirm from "react-lib/apps/common/cnmi/ModConfirm";
import SearchBoxDropdown from "react-lib/appcon/common/SearchBoxDropdown";

import DateTextBox from "react-lib/apps/common/DateTextBox";

import ErrorMessage from "react-lib/apps/common/ErrorMessage";
import ModInfo from "react-lib/apps/common/ModInfo";


// UX
import CardMedicationErrorListUX from "./CardMedicationErrorListUX";
import CardMedicationErrorWorking from "./CardMedicationErrorWorking";
import ModSelectQuarter from "./ModSelectQuarter";

// Interface
import { RunSequence, State } from "./sequence/MedErrorList";

// Utils
import { formatDatetime } from "react-lib/utils/dateUtils";
import { useIntl } from "react-intl";

// types
type CardMedicationErrorListProps = {
  onEvent: (e: any) => any;
  setProp: (key: string, value: any, callback?: () => any) => any;
  // seq
  runSequence: RunSequence;
  MedErrorListSequence: State["MedErrorListSequence"];
  // data
  userId?: number;
  // options
  masterOptions?: Record<string, any[]>;
  // CommonInterface
  searchedItemListWithKey?: Record<string, any>;
  django?: Record<string, any>;
  selectedDivision?: Record<string, any>;
  buttonLoadCheck?: Record<string, any>;
  // config
  allowedPrint?: boolean;
};

// Const
const CenterStyle = {
  display: "flex",
  justifyContent: "center",
  textAlign: "center",
} as CSSProperties;

const BUTTON_ACTIONS = {
  SEARCH: "search",
  PRINT: "print",
};

const USER_SEARCH_MEL = "User_MEL";

const CARD_MEDICATION_ERROR_LIST = "CardMedicationErrorList";

const ACTION_SEARCH = `${CARD_MEDICATION_ERROR_LIST}_${BUTTON_ACTIONS.SEARCH}`;
const ACTION_PRINT = `${CARD_MEDICATION_ERROR_LIST}_${BUTTON_ACTIONS.PRINT}`;

const CardMedicationErrorList = (props: CardMedicationErrorListProps) => {
  const intl = useIntl();
  // Data
  const [quarterDetail, setQuarterDetail] = useState<any>({});
  // Selected
  const [selectedEdit, setSelectedEdit] = useState<any>(null);
  const [selectedDelete, setSelectedDelete] = useState<any>(null);
  // Checked
  const [checkedIds, setCheckedIds] = useState<number[]>([]);
  // Mod
  const [openModInfo, setOpenModInfo] = useState<any>(null);
  const [openModFeedbackReport, setOpenModFeedbackReport] = useState<boolean>(false);

  const [loadingSelectId, setLoadingSelectId] = useState<null | number>(null);

  // Memo Effect
  const filterMedError = useMemo(() => {
    return props.MedErrorListSequence?.filterMedError;
  }, [props.MedErrorListSequence?.filterMedError]);

  const medErrorList = useMemo(() => {
    return props.MedErrorListSequence?.medErrorList;
  }, [props.MedErrorListSequence?.medErrorList]);

  const medErrorWorking = useMemo(() => {
    return props.MedErrorListSequence?.medErrorWorking;
  }, [props.MedErrorListSequence?.medErrorWorking]);

  // Effect
  useEffect(() => {
    props.runSequence({
      sequence: "MedErrorList",
      restart: true,
      byCorrective: !props.allowedPrint,
      nextIndex: "SearchMedError",
      card: CARD_MEDICATION_ERROR_LIST,
    });
  }, []);

  useEffect(() => {
    const user = medErrorWorking?.user;

    if (!user) {
      return;
    }

    // * เมื่อข้อมูลโหลดเรียบร้อย
    setLoadingSelectId(null);
    setSelectedEdit(medErrorWorking);
  }, [medErrorWorking?.user]);

  // Memo Callback
  const handleCheck = useCallback(
    (e: any, data: any) => {
      let ids = [...checkedIds];
      if (data.checked) {
        ids.push(data.name);
      } else {
        ids = ids.filter((id) => id !== data.name);
      }

      setCheckedIds(ids);
    },
    [checkedIds]
  );

  // Callback
  const mapUserOptions = useCallback((items: any[]) => {
    return items.map((item: any) => ({
      key: item.id,
      value: item.id,
      text: item.full_name,
    }));
  }, []);

  const handleSelectedItem = useCallback(
    async (value: any, key: any) => {
      const items: any[] = props.searchedItemListWithKey?.[USER_SEARCH_MEL] || [];

      const user = items.find((item: any) => item.id === key);

      handleChange(null, {
        name: "stakeholder",
        value: user?.id ? { id: user.id, full_name: user.full_name } : null,
      });
    },
    [props.searchedItemListWithKey]
  );

  const handlePrint = useCallback(
    (item: any) => {
      props.runSequence({
        sequence: "MedErrorList",
        action: "print",
        list: [item],
      });
    },
    [quarterDetail]
  );

  // * ต้องเรียก EditMedError จากที่นี่เนื่องจากต้องทำ loading ปุ่ม
  const handleSelect = useCallback((data: any) => {
    props.runSequence({
      sequence: "MedErrorList",
      id: data.id,
      nextIndex: "EditMedError",
      readOnly: data.readOnly,
    });

    setLoadingSelectId(data.id);
  }, []);

  const medErrorItems = useMemo(() => {
    return (medErrorList || []).map((item: any, index: number) => {
      const centerFormat = Object.fromEntries(
        "type_name,type_detail,cause_detail,stakeholder_name,status".split(",").map<any>((k) => [
          k,
          <div key={`center-${k}`} style={CenterStyle}>
            {item[k]}
          </div>,
        ])
      );

      return {
        ...item,
        chk: (
          <div style={{ display: "grid", placeContent: "center" }}>
            {item.status !== "CANCELED" && (
              <Checkbox
                checked={checkedIds.includes(item.id)}
                name={item.id}
                onChange={handleCheck}
              />
            )}
          </div>
        ),
        datetime: <div style={CenterStyle}>{formatDatetime(item.created)}</div>,
        no: <div style={CenterStyle}>{index + 1}</div>,
        drug_name: (
          <div style={CenterStyle}>{`[${item.drug_code || ""}][${item.drug_name || ""}]`}</div>
        ),
        ...centerFormat,
        action: (
          <div style={CenterStyle}>
            <RenderAction
              data={item}
              django={props.django}
              allowedPrint={props.allowedPrint}
              selectedDivision={props.selectedDivision}
              userId={props.userId}
              loadingSelectId={loadingSelectId}
              // callback
              onPrint={handlePrint}
              onSelect={handleSelect}
              onDelete={setSelectedDelete}
            />
          </div>
        ),
      };
    });
  }, [
    medErrorList,
    props.userId,
    props.django,
    props.selectedDivision,
    checkedIds,
    quarterDetail,
    loadingSelectId,
  ]);

  const isCorrectiveDiv = useMemo(() => {
    const data = medErrorWorking || {};
    const correctiveDiv =
      data.detail?.corrective_division &&
      data.detail?.corrective_division === props.selectedDivision?.id;

    return correctiveDiv;
  }, [medErrorWorking?.detail, props.userId]);

  const isCorrectiveUser = useMemo(() => {
    const data = medErrorWorking || {};
    const correctiveUser =
      data.detail?.corrective_user === Number(props.userId) && data.detail?.corrective_user;

    return correctiveUser;
  }, [medErrorWorking?.detail, props.userId]);

  const isActionComplete = useMemo(() => {
    return isCorrectiveDiv || isCorrectiveUser;
  }, [medErrorWorking?.detail, props.userId, isCorrectiveDiv, isCorrectiveUser]);

  const divisionDrugOptions = useMemo(() => {
    return [
      {
        key: "ALL",
        text: "แสดงทั้งหมด",
        value: "ALL",
      },
      ...(props.masterOptions?.division || []),
    ];
  }, [props.masterOptions?.division]);

  const printableList = useMemo(() => {
    return (medErrorList || []).filter((item) => item.status !== "CANCELED");
  }, [medErrorList]);

  const handleCheckAll = useCallback(
    (e: any, data: any) => {
      let ids = [];

      if (data.checked) {
        ids = printableList.map((item: any) => item.id);
      }

      setCheckedIds(ids);
    },
    [checkedIds, printableList]
  );

  const headers = useMemo(() => {
    const list: any[] = [
      "ลำดับ",
      "วัน-เวลาที่รายงาน",
      "รายการที่คลาดเคลื่อน",
      "ประเภท",
      "ลักษณะ",
      "สาเหตุ",
      "ผู้ที่ทำให้เกิดความผิดพลาด",
      "สถานะ",
      "Action",
    ];

    if (props.allowedPrint) {
      list.unshift(
        <Checkbox
          id="CardMedicationErrorList-Checkbox-All"
          checked={printableList.length === checkedIds.length && printableList.length > 0}
          onChange={handleCheckAll}
        />
      );
    }

    return list;
  }, [checkedIds, printableList]);

  const handleChange = (e: any, v: any) => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "change",
      data: {
        ...filterMedError,
        [v.name]: v.value,
        ...(v.name === "stakeholder"
          ? {
              stakeholder: v.value?.id || null,
              stakeholder_name: v.value?.full_name || null,
            }
          : {}),
      },
    });
  };

  const handleRefresh = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "search",
      card: CARD_MEDICATION_ERROR_LIST,
      byCorrective: !props.allowedPrint,
    });
  };

  const handleApprove = () => {
    handleClose();

    props.runSequence({
      sequence: "MedErrorList",
      action: "delete",
      data: {
        id: selectedDelete?.id,
      },
      byCorrective: !props.allowedPrint,
      callback: (status: string, message: any) => {
        setOpenModInfo({ status, message });

        if (status === "success") {
          handleRefresh();
        }
      },
    });
  };

  const handleClose = () => {
    setSelectedDelete(null);
  };

  const onCloseModSuccess = () => {
    setOpenModInfo(null);
  };

  const handleCloseModEdit = async () => {
    setSelectedEdit(null);

    await props.setProp("MedErrorListSequence.medErrorWorking", {});

    await props.setProp("MedErrorListSequence.sequenceIndex", "SearchMedError");
  };

  const handleEdited = async () => {
    await handleCloseModEdit();

    handleRefresh();
  };

  const handleChangeDate = (name: string) => (value: string) => {
    handleChange(null, { value, name });
  };

  const handleOpenModFeedbackReport = () => {
    setOpenModFeedbackReport(true);
  };

  const handleCloseModFeedbackReport = () => {
    setOpenModFeedbackReport(false);
  };

  const handleSaveQuarter = (detail: any) => {
    setQuarterDetail(detail);
    handleCloseModFeedbackReport();
  };

  const handleExportExcel = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "export_excel",
    });
  };

  const handleClear = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "change",
      data: {
        division: "ALL",
        start_date: "",
        end_date: "",
      },
    });
  };

  const handlePrintReport = () => {
    props.runSequence({
      sequence: "MedErrorList",
      action: "print",
      list: (medErrorList || []).filter((item: any) => checkedIds.includes(item.id)),
      quarterDetail,
      card: CARD_MEDICATION_ERROR_LIST,
    });
  };

  const handleClickStatistics = async () => {
    // * ห้ามกดเมื่อกำลัง select
    if (loadingSelectId) {
      return;
    }

    await props.setProp("MedErrorListSequence.medErrorStatistics", {
      open: true,
      start_date: filterMedError?.start_date,
      end_date: filterMedError?.end_date,
      items: [],
    });

    props.runSequence({
      sequence: "MedErrorList",
      action: "statistics_search",
      card: CARD_MEDICATION_ERROR_LIST,
    });
  };

  const handleClearQuarter = () => {
    setQuarterDetail({});
  };

  console.log("CardMedicationErrorListUX", props, selectedEdit);

  return (
    <div style={{ padding: props.allowedPrint ? "" : "0 5px" }}>
      <CardMedicationErrorListUX
        // data
        filterMedError={filterMedError}
        medErrorList={medErrorItems}
        quarter={`${quarterDetail.quarter || ""}${
          quarterDetail.quarter || quarterDetail.year ? "/" : ""
        }${quarterDetail.year || ""}`}
        // options
        divisionDrugOptions={divisionDrugOptions}
        // config
        allowedPrint={props.allowedPrint}
        // callback
        onChange={handleChange}
        onRefresh={handleRefresh}
        onOpenModFeedbackReport={handleOpenModFeedbackReport}
        onExportExcel={handleExportExcel}
        onClear={handleClear}
        onClickStatistics={handleClickStatistics}
        onClearQuarter={handleClearQuarter}
        // Header
        headers={headers}
        // Element
        StartDate={
          <DateTextBox
            value={filterMedError?.start_date || ""}
            inputFluid
            inputStyle={{ width: "100%" }}
            style={{ width: "100%" }}
            onChange={handleChangeDate("start_date")}
          />
        }
        EndDate={
          <DateTextBox
            value={filterMedError?.end_date || ""}
            inputFluid
            inputStyle={{ width: "100%" }}
            style={{ width: "100%" }}
            onChange={handleChangeDate("end_date")}
          />
        }
        statusDropdown={
          <DropdownOptions
            value={filterMedError?.status || ""}
            options={props.masterOptions?.medErrorStatus}
            name={"status"}
            fluid
            multiple
            search
            style={{ width: "100%" }}
            onChange={handleChange}
          />
        }
        typeDropdown={
          <DropdownOptions
            value={filterMedError?.type || ""}
            options={props.masterOptions?.medErrorType}
            name={"type"}
            fluid
            multiple
            search
            style={{ width: "100%" }}
            onChange={handleChange}
          />
        }
        SearchBox={
          <SearchBoxDropdown
            onEvent={props.onEvent}
            // config
            type="User"
            id="MEL"
            style={{ width: "100%" }}
            fluid
            useWithKey
            icon="search"
            limit={20}
            // Select
            searchedItemListWithKey={props.searchedItemListWithKey}
            selectedItem={filterMedError?.stakeholder || null}
            setSelectedItem={handleSelectedItem}
            // options
            mapOptions={mapUserOptions}
          />
        }
        ButtonSearch={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handleRefresh}
            // data
            paramKey={ACTION_SEARCH}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_SEARCH]}
            // config
            color={"blue"}
            name={BUTTON_ACTIONS.SEARCH}
            size="small"
            title={intl.formatMessage({ id: "ค้นหา" })}
          />
        }
        ButtonPrintReport={
          <ButtonLoadCheck
            // function
            setProp={props.setProp}
            onClick={handlePrintReport}
            // data
            paramKey={ACTION_PRINT}
            buttonLoadCheck={props.buttonLoadCheck?.[ACTION_PRINT]}
            // config
            disabled={checkedIds.length === 0 || !quarterDetail.quarter}
            color={"green"}
            name={BUTTON_ACTIONS.PRINT}
            size="small"
            title={intl.formatMessage({ id: "พิมพ์รายงาน Feedback" })}
            style={{ minWidth: "max-content" }}
          />
        }
        languageUX={props.languageUX}
      />

      <ModConfirm
        openModal={!!selectedDelete}
        content={
          <div style={{ textAlign: "center", margin: "-0.25rem 0 -0.75rem" }}>
            ต้องการยกเลิกรายการนี้
          </div>
        }
        onApprove={handleApprove}
        onDeny={handleClose}
      />

      <ModInfo
        open={!!openModInfo}
        titleColor={openModInfo?.status === "success" ? "green" : "red"}
        titleName={openModInfo?.status === "success" ? "ยกเลิกรายการสำเร็จ" : ""}
        onApprove={onCloseModSuccess}
        style={{ top: "calc(50vh - 130px)" }}
      >
        {openModInfo?.status === "error" && <ErrorMessage error={openModInfo?.message} />}
      </ModInfo>

      <Modal open={!!selectedEdit} closeOnDimmerClick closeIcon onClose={handleCloseModEdit}>
        <CardMedicationErrorWorking
          onEvent={props.onEvent}
          setProp={props.setProp}
          // seq
          runSequence={props.runSequence}
          // data
          // medErrId={selectedEdit?.id}
          MedErrorListSequence={props.MedErrorListSequence}
          selectedDivision={props.selectedDivision}
          searchedItemListWithKey={props.searchedItemListWithKey}
          // options
          masterOptions={props.masterOptions}
          // config
          showSolution={isCorrectiveUser}
          isActionComplete={isActionComplete}
          readOnly={!!selectedEdit?.readOnly}
          disabledEdit={isCorrectiveDiv}
          editMode
          hideEditQuarter={!props.allowedPrint || !!selectedEdit?.readOnly}
          // callback
          onEdited={handleEdited}
          languageUX={props.languageUX}
        />
      </Modal>

      <Modal
        open={openModFeedbackReport}
        closeOnDimmerClick
        // callback
        onClose={handleCloseModFeedbackReport}
      >
        <ModSelectQuarter
          detail={quarterDetail}
          onSave={handleSaveQuarter}
          // callback
          onClose={handleCloseModFeedbackReport}
          languageUX={props.languageUX}
        />
      </Modal>
    </div>
  );
};

/* ------------------------------------------------------ */

/*                      RenderAction                      */

/* ------------------------------------------------------ */
type RenderActionProps = {
  data: Record<string, any>;
  loadingSelectId?: number | null;
  // callback
  onPrint: (data: any) => any;
  onSelect: (data: any) => any;
  onDelete: (data: any) => any;
} & Pick<CardMedicationErrorListProps, "allowedPrint" | "django" | "selectedDivision" | "userId">;

const RenderAction = React.memo((props: RenderActionProps) => {
  // Memo
  const editable = useMemo(() => {
    const types: string[] = props.django?.user?.role_types || [];

    return (
      types.includes("PHARMACIST") ||
      props.data.stakeholder === props.userId ||
      props.data.corrective_user === props.userId ||
      props.data.corrective_division === props.selectedDivision?.id ||
      props.data.editable
    );
  }, [props.data, props.django, props.userId, props.selectedDivision]);

  const isDelete = useMemo(() => {
    return editable && props.data.status === "INCOMPLETE";
  }, [props.data, editable]);

  // Handler
  const handlePrint = () => {
    props.onPrint(props.data);
  };

  const handleSelect = () => {
    if (!props.loadingSelectId) {
      props.onSelect({
        ...props.data,
        readOnly: props.data.status !== "INCOMPLETE",
      });
    }
  };

  const handleDelete = () => {
    props.onDelete(props.data);
  };

  return (
    <>
      {props.allowedPrint && (
        <Icon
          name="print"
          color="black"
          disabled={!props.data.quarter_no || props.data.status === "CANCELED"}
          style={{
            margin: "0 0.5rem",
            cursor: props.data.quarter_no ? "pointer" : "",
          }}
          onClick={handlePrint}
        />
      )}

      <div style={{ position: "relative" }}>
        {editable ? (
          <Icon
            name={props.data.status === "INCOMPLETE" ? "edit outline" : "eye"}
            color={props.data.status === "INCOMPLETE" ? "yellow" : "black"}
            style={{ margin: "0 0.5rem", cursor: "pointer" }}
            onClick={handleSelect}
          />
        ) : (
          <Icon
            name="edit outline"
            color="grey"
            disabled
            style={{ margin: "0 0.5rem", cursor: "pointer" }}
          />
        )}

        <Dimmer
          active={props.loadingSelectId === props.data.id}
          size="mini"
          inverted
          style={{
            background: "transparent",
            margin: "-4px 0 0 0",
          }}
        >
          <Loader size="mini" inline />
        </Dimmer>
      </div>

      {props.allowedPrint && (
        <Icon
          name="trash alternate"
          color={!isDelete ? "grey" : "red"}
          disabled={!isDelete}
          style={{ margin: "0 0.5rem", cursor: "pointer" }}
          onClick={handleDelete}
        />
      )}
    </>
  );
});

RenderAction.displayName = "RenderAction";

CardMedicationErrorList.displayName = "CardMedicationErrorList";

export default React.memo(CardMedicationErrorList);
