import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  Table
} from 'react-lib/frameworks/Table'
import {
  FormGroup,
  FormField,
  Icon,
  Dropdown,
  Button,
  Label
} from 'semantic-ui-react'

const CardMedicationErrorListUX = (props: any) => {
    return(
      <div
        id="CardMedicationErrorListUX-div-0"
        style={{padding: "0 5px"}}>
        <div
          id="CardMedicationErrorListUX-div-1"
          style={{fontWeight: "bold", fontSize: "1.2rem", padding: "1rem 0"}}>
          Medication Error List
        </div>
        <div
          className="ui form"
          id="CardMedicationErrorListUX-div-41">

          <FormGroup
            id="CardMedicationErrorListUX-FormGroup-42"
            inline={true}>
            <FormField
              id="CardMedicationErrorListUX-FormField-44"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-52"
                style={{width: "60px"}}>
                วันที่
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-45"
              inline={true}
              width={2}>
              <div
                id="CardMedicationErrorListUX-div-86"
                style={{width: "100%"}}>
                {props.StartDate}
              </div>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-46"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-54">
                ถึง
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-47"
              inline={true}
              width={2}>
              <div
                id="CardMedicationErrorListUX-div-87"
                style={{width: "100%"}}>
                {props.EndDate}
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-88"
              inline={true}
              width={1}>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-48"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-56">
                ผู้ที่ทำให้เกิดความผิดพลาด
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-49"
              inline={true}
              width={3}>
              <div
                id="CardMedicationErrorListUX-div-57"
                style={{width: "100%", marginRight: "1rem"}}>
                {props.SearchBox}
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-50"
              inline={true}>
              <div
                id="CardMedicationErrorListUX-div-93">
                {props.ButtonPrintReport}
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-51"
              inline={true}>
              <div
                id="CardMedicationErrorListUX-div-59"
                style={{cursor: "pointer"}}>

                <Icon
                  className="grey large"
                  id="CardMedicationErrorListUX-Icon-60"
                  name="cog"
                  onClick={props.onOpenModFeedbackReport}>
                </Icon>
              </div>
            </FormField>
            <FormField
              className={props.allowedPrint ? "hidden" : ""}
              id="CardMedicationErrorListUX-FormField-89"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-91">
                หน่วยงาน
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "hidden" : ""}
              id="CardMedicationErrorListUX-FormField-90">
              <Dropdown
                id="CardMedicationErrorListUX-Dropdown-92"
                name="division"
                onChange={props.onChange}
                options={props.divisionDrugOptions}
                search={true}
                selection={true}
                value={props.filterMedError?.division || ""}>
              </Dropdown>
            </FormField>
          </FormGroup>
          <FormGroup
            id="CardMedicationErrorListUX-FormGroup-43"
            inline={true}>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-61"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-71"
                style={{width: "60px"}}>
                หน่วยงาน
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-62"
              inline={true}
              width={2}>
              <Dropdown
                fluid={true}
                id="CardMedicationErrorListUX-Dropdown-72"
                name="division"
                onChange={props.onChange}
                options={props.divisionDrugOptions}
                search={true}
                selection={true}
                style={{width: "100%"}}
                value={props.filterMedError?.division || ""}>
              </Dropdown>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-65"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-75">
                สถานะ
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-63"
              inline={true}
              width={2}>
              <div
                id="CardMedicationErrorListUX-div-73"
                style={{width: "100%"}}>
                {props.statusDropdown}
              </div>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-66"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-76">
                ประเภท
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-64"
              inline={true}
              width={4}>
              <div
                id="CardMedicationErrorListUX-div-74"
                style={{width: "100%"}}>
                {props.typeDropdown}
              </div>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-67"
              inline={true}>
              <div
                id="CardMedicationErrorListUX-div-94">
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              id="CardMedicationErrorListUX-FormField-68"
              inline={true}>
              <Button
                color="yellow"
                id="CardMedicationErrorListUX-Button-78"
                onClick={props.onClear}
                size="small"
                style={{minWidth: "max-content"}}>
                ล้างการค้นหา
              </Button>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-69"
              inline={true}>
              <label
                id="CardMedicationErrorListUX-label-79"
                style={{color: "#767676", minWidth: "max-content"}}>
                ไตรมาสที่
              </label>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-70"
              inline={true}>
              <div
                id="CardMedicationErrorListUX-div-95"
                style={{display: "flex", alignItems: "center"}}>

                <Label
                  id="CardMedicationErrorListUX-Label-97"
                  size="large"
                  style={{minWidth: "80px", textAlign: "center"}}>
                  {props.quarter || "\u00a0"}
                </Label>
                <div
                  id="CardMedicationErrorListUX-div-96"
                  style={{cursor: "pointer", display: props.quarter ? "": "none"}}>

                  <Icon
                    className="grey"
                    id="CardMedicationErrorListUX-Icon-98"
                    name="close"
                    onClick={props.onClearQuarter}>
                  </Icon>
                </div>
              </div>
            </FormField>
            <div
              className="field"
              id="CardMedicationErrorListUX-div-81"
              style={{flex: 1}}>

            </div>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-82">
              <Button
                color="orange"
                id="CardMedicationErrorListUX-Button-84"
                onClick={props.onClickStatistics}
                size="small"
                style={{minWidth: "max-content"}}>
                สถิติ
              </Button>
            </FormField>
            <FormField
              className={props.allowedPrint ? "" : "hidden"}
              id="CardMedicationErrorListUX-FormField-83">
              <Button
                color="brown"
                disabled={!props.medErrorList?.length}
                id="CardMedicationErrorListUX-Button-85"
                onClick={props.onExportExcel}
                size="small"
                style={{minWidth: "max-content"}}>
                Export Excel
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          id="CardMedicationErrorListUX-div-3">

          <Table
            data={props.medErrorList}
            headers={props.headers ? props.headers : "Date,Drug,Error Type,Brief,Division,Stakeholder,Status,Edit,Del"}
            id="CardMedicationErrorListUX-Table-4"
            keys={`${props.allowedPrint ? "chk," : ""}no,datetime,drug_name,type_name,type_detail,cause_detail,stakeholder_name,status,action`}
            minRows="10"
            showPagination={false}
            style={{minHeight: "300px", maxHeight: "70vh"}}
            widths={`${props.allowedPrint ? "70," : ""}70,140,auto,auto,auto,auto,auto,120,120`}>
          </Table>
        </div>
      </div>
    )
}


export default CardMedicationErrorListUX

export const screenPropsDefault = {"userSearch":"[User Search Box]"}

/* Date Time : Tue Mar 18 2025 15:16:20 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "Medication Error List"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", padding: \"1rem 0\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 3,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-3"
        }
      },
      "seq": 41,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 4,
      "name": "Table",
      "parent": 3,
      "props": {
        "data": {
          "type": "code",
          "value": "props.medErrorList"
        },
        "headers": {
          "type": "code",
          "value": "props.headers ? props.headers : \"Date,Drug,Error Type,Brief,Division,Stakeholder,Status,Edit,Del\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Table-4"
        },
        "keys": {
          "type": "code",
          "value": "`${props.allowedPrint ? \"chk,\" : \"\"}no,datetime,drug_name,type_name,type_detail,cause_detail,stakeholder_name,status,action`"
        },
        "minRows": {
          "type": "value",
          "value": "10"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{minHeight: \"300px\", maxHeight: \"70vh\"}"
        },
        "widths": {
          "type": "code",
          "value": "`${props.allowedPrint ? \"70,\" : \"\"}70,140,auto,auto,auto,auto,auto,120,120`"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": null,
      "id": 41,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-41"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 42,
      "name": "FormGroup",
      "parent": 41,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormGroup-42"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 42,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 43,
      "name": "FormGroup",
      "parent": 41,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormGroup-43"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 43,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 44,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-44"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 44,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 45,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-45"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 45,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 46,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-46"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 46,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 47,
      "name": "FormField",
      "parent": 42,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-47"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 47,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 48,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-48"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 49,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 49,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-49"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "3"
        }
      },
      "seq": 50,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 50,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-50"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 51,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 51,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-51"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 88,
      "void": false
    },
    {
      "from": null,
      "id": 52,
      "name": "label",
      "parent": 44,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-52"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 52,
      "void": false
    },
    {
      "from": null,
      "id": 54,
      "name": "label",
      "parent": 46,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-54"
        }
      },
      "seq": 54,
      "void": false
    },
    {
      "from": null,
      "id": 56,
      "name": "label",
      "parent": 48,
      "props": {
        "children": {
          "type": "value",
          "value": "ผู้ที่ทำให้เกิดความผิดพลาด"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-56"
        }
      },
      "seq": 56,
      "void": false
    },
    {
      "from": null,
      "id": 57,
      "name": "div",
      "parent": 49,
      "props": {
        "children": {
          "type": "code",
          "value": "props.SearchBox"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-57"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\", marginRight: \"1rem\"}"
        }
      },
      "seq": 57,
      "void": false
    },
    {
      "from": null,
      "id": 59,
      "name": "div",
      "parent": 51,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-59"
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\"}"
        }
      },
      "seq": 59,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 60,
      "name": "Icon",
      "parent": 59,
      "props": {
        "className": {
          "type": "value",
          "value": "grey large"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Icon-60"
        },
        "name": {
          "type": "value",
          "value": "cog"
        },
        "onClick": {
          "type": "code",
          "value": "props.onOpenModFeedbackReport"
        }
      },
      "seq": 60,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 61,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-61"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 61,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 62,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-62"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 62,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 63,
      "name": "FormField",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-63"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "2"
        }
      },
      "seq": 64,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 64,
      "name": "FormField",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-64"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "4"
        }
      },
      "seq": 66,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 65,
      "name": "FormField",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-65"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 63,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 66,
      "name": "FormField",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-66"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 65,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 67,
      "name": "FormField",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-67"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 67,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 68,
      "name": "FormField",
      "parent": 43,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-68"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 68,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 69,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-69"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 69,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 70,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-70"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 70,
      "void": false
    },
    {
      "from": null,
      "id": 71,
      "name": "label",
      "parent": 61,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-71"
        },
        "style": {
          "type": "code",
          "value": "{width: \"60px\"}"
        }
      },
      "seq": 71,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 72,
      "name": "Dropdown",
      "parent": 62,
      "props": {
        "fluid": {
          "type": "code",
          "value": "true"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Dropdown-72"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionDrugOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        },
        "value": {
          "type": "code",
          "value": "props.filterMedError?.division || \"\""
        }
      },
      "seq": 72,
      "void": false
    },
    {
      "from": null,
      "id": 73,
      "name": "div",
      "parent": 63,
      "props": {
        "children": {
          "type": "code",
          "value": "props.statusDropdown"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-73"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 73,
      "void": false
    },
    {
      "from": null,
      "id": 74,
      "name": "div",
      "parent": 64,
      "props": {
        "children": {
          "type": "code",
          "value": "props.typeDropdown"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-74"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 74,
      "void": false
    },
    {
      "from": null,
      "id": 75,
      "name": "label",
      "parent": 65,
      "props": {
        "children": {
          "type": "value",
          "value": "สถานะ"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-75"
        }
      },
      "seq": 75,
      "void": false
    },
    {
      "from": null,
      "id": 76,
      "name": "label",
      "parent": 66,
      "props": {
        "children": {
          "type": "value",
          "value": "ประเภท"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-76"
        }
      },
      "seq": 76,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 78,
      "name": "Button",
      "parent": 68,
      "props": {
        "children": {
          "type": "value",
          "value": "ล้างการค้นหา"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Button-78"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClear"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 78,
      "void": false
    },
    {
      "from": null,
      "id": 79,
      "name": "label",
      "parent": 69,
      "props": {
        "children": {
          "type": "value",
          "value": "ไตรมาสที่"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-79"
        },
        "style": {
          "type": "code",
          "value": "{color: \"#767676\", minWidth: \"max-content\"}"
        }
      },
      "seq": 79,
      "void": false
    },
    {
      "from": null,
      "id": 81,
      "name": "div",
      "parent": 43,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-81"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 81,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 82,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-82"
        }
      },
      "seq": 82,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 83,
      "name": "FormField",
      "parent": 43,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-83"
        }
      },
      "seq": 83,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 84,
      "name": "Button",
      "parent": 82,
      "props": {
        "children": {
          "type": "value",
          "value": "สถิติ"
        },
        "color": {
          "type": "value",
          "value": "orange"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Button-84"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClickStatistics"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 84,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 85,
      "name": "Button",
      "parent": 83,
      "props": {
        "children": {
          "type": "value",
          "value": "Export Excel"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "disabled": {
          "type": "code",
          "value": "!props.medErrorList?.length"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Button-85"
        },
        "onClick": {
          "type": "code",
          "value": "props.onExportExcel"
        },
        "size": {
          "type": "value",
          "value": "small"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"max-content\"}"
        }
      },
      "seq": 85,
      "void": false
    },
    {
      "from": null,
      "id": 86,
      "name": "div",
      "parent": 45,
      "props": {
        "children": {
          "type": "code",
          "value": "props.StartDate"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-86"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 86,
      "void": false
    },
    {
      "from": null,
      "id": 87,
      "name": "div",
      "parent": 47,
      "props": {
        "children": {
          "type": "code",
          "value": "props.EndDate"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-87"
        },
        "style": {
          "type": "code",
          "value": "{width: \"100%\"}"
        }
      },
      "seq": 87,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 88,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"\" : \"hidden\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-88"
        },
        "inline": {
          "type": "code",
          "value": "true"
        },
        "width": {
          "type": "code",
          "value": "1"
        }
      },
      "seq": 48,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 89,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"hidden\" : \"\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-89"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 89,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 90,
      "name": "FormField",
      "parent": 42,
      "props": {
        "className": {
          "type": "code",
          "value": "props.allowedPrint ? \"hidden\" : \"\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-FormField-90"
        }
      },
      "seq": 90,
      "void": false
    },
    {
      "from": null,
      "id": 91,
      "name": "label",
      "parent": 89,
      "props": {
        "children": {
          "type": "value",
          "value": "หน่วยงาน"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-label-91"
        }
      },
      "seq": 91,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 92,
      "name": "Dropdown",
      "parent": 90,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Dropdown-92"
        },
        "name": {
          "type": "value",
          "value": "division"
        },
        "onChange": {
          "type": "code",
          "value": "props.onChange"
        },
        "options": {
          "type": "code",
          "value": "props.divisionDrugOptions"
        },
        "search": {
          "type": "code",
          "value": "true"
        },
        "selection": {
          "type": "code",
          "value": "true"
        },
        "value": {
          "type": "code",
          "value": "props.filterMedError?.division || \"\""
        }
      },
      "seq": 92,
      "void": false
    },
    {
      "from": null,
      "id": 93,
      "name": "div",
      "parent": 50,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonPrintReport"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-93"
        }
      },
      "seq": 93,
      "void": false
    },
    {
      "from": null,
      "id": 94,
      "name": "div",
      "parent": 67,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-94"
        }
      },
      "seq": 94,
      "void": false
    },
    {
      "from": null,
      "id": 95,
      "name": "div",
      "parent": 70,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-95"
        },
        "style": {
          "type": "code",
          "value": "{display: \"flex\", alignItems: \"center\"}"
        }
      },
      "seq": 95,
      "void": false
    },
    {
      "from": null,
      "id": 96,
      "name": "div",
      "parent": 95,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-div-96"
        },
        "style": {
          "type": "code",
          "value": "{cursor: \"pointer\", display: props.quarter ? \"\": \"none\"}"
        }
      },
      "seq": 97,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 97,
      "name": "Label",
      "parent": 95,
      "props": {
        "children": {
          "type": "code",
          "value": "props.quarter || \"\\u00a0\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Label-97"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{minWidth: \"80px\", textAlign: \"center\"}"
        }
      },
      "seq": 96,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 98,
      "name": "Icon",
      "parent": 96,
      "props": {
        "className": {
          "type": "value",
          "value": "grey"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorListUX-Icon-98"
        },
        "name": {
          "type": "value",
          "value": "close"
        },
        "onClick": {
          "type": "code",
          "value": "props.onClearQuarter"
        }
      },
      "seq": 98,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedicationErrorListUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "userSearch": "[User Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
