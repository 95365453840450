import React from 'react';
import {IntlProvider, FormattedMessage, useIntl} from 'react-intl';

import {
  FormGroup,
  FormField,
  Button,
  Label
} from 'semantic-ui-react'
import {
  DateTextBox
} from 'react-lib/apps/common'
import {
  Table
} from 'react-lib/frameworks/Table'

const CardMedicationErrorStatisticsUX = (props: any) => {
    return(
      <div
        id="CardMedicationErrorStatisticsUX-div-0"
        style={{padding: "0 5px"}}>
        <div
          id="CardMedicationErrorStatisticsUX-div-1"
          style={{fontWeight: "bold", fontSize: "1.2rem", padding: "1rem 0"}}>
          สถิติการเกิดอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Medication Error)
        </div>
        <div
          className="ui form"
          id="CardMedicationErrorStatisticsUX-div-2">

          <FormGroup
            id="CardMedicationErrorStatisticsUX-FormGroup-3"
            inline={true}>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-4"
              inline={true}>
              <label
                id="CardMedicationErrorStatisticsUX-label-8">
                วันที่เกิดปัญหา
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-5"
              inline={true}>
              <DateTextBox
                id="CardMedicationErrorStatisticsUX-DateTextBox-9"
                onChange={(date)=>props.onChange(null,{name: "start_date", value: date})}
                value={props.medErrorStatistics?.start_date}>
              </DateTextBox>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-6"
              inline={true}>
              <label
                id="CardMedicationErrorStatisticsUX-label-11">
                ถึง
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-7"
              inline={true}>
              <DateTextBox
                id="CardMedicationErrorStatisticsUX-DateTextBox-10"
                onChange={(date)=>props.onChange(null,{name: "end_date", value: date})}
                value={props.medErrorStatistics?.end_date}>
              </DateTextBox>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-20">
              <div
                id="CardMedicationErrorStatisticsUX-div-26">
                {props.ButtonSearch}
              </div>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-27">
              <Button
                color="yellow"
                id="CardMedicationErrorStatisticsUX-Button-28"
                onClick={props.onBack}>
                ย้อนกลับ
              </Button>
            </FormField>
            <div
              className="field"
              id="CardMedicationErrorStatisticsUX-div-13"
              style={{flex: 1}}>

            </div>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-14">
              <label
                id="CardMedicationErrorStatisticsUX-label-17">
                จำนวน Error ทั้งหมด
              </label>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-15"
              width={{minWidth: "2rem"}}>
              <Label
                id="CardMedicationErrorStatisticsUX-Label-23"
                size="large"
                style={{backgroundColor: "transparent", border: "2px solid red", borderRadius: "3px", color: "red"}}>
                {props.number || "0"}
              </Label>
            </FormField>
            <FormField
              id="CardMedicationErrorStatisticsUX-FormField-16">
              <Button
                color="brown"
                id="CardMedicationErrorStatisticsUX-Button-19"
                onClick={props.onExportExcel}>
                Export Excel
              </Button>
            </FormField>
          </FormGroup>
        </div>
        <div
          id="CardMedicationErrorStatisticsUX-div-24">

          <Table
            data={props.items || []}
            headers="ลำดับ,ผู้ที่ทำให้เกิดความผิดพลาด,ประเภท,รายการที่คลาดเคลื่อน,จำนวน"
            id="CardMedicationErrorStatisticsUX-Table-25"
            keys="no,stakeholder_name, type_name,drug_name,qty"
            showPagination={false}
            style={{height: "calc(100vh - 15rem)"}}
            widths="150,auto,auto,auto,200">
          </Table>
        </div>
      </div>
    )
}


export default CardMedicationErrorStatisticsUX

export const screenPropsDefault = {"userSearch":"[User Search Box]"}

/* Date Time : Thu Mar 20 2025 15:59:09 GMT+0700 (Indochina Time)
/* Original screen Data ************************************************************

{
  "elements": [
    {
      "id": 0,
      "name": "div",
      "parent": -1,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-div-0"
        },
        "style": {
          "type": "code",
          "value": "{padding: \"0 5px\"}"
        }
      },
      "seq": 0
    },
    {
      "from": null,
      "id": 1,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": "สถิติการเกิดอุบัติการณ์ความเสี่ยงและความคลาดเคลื่อนทางยา (Medication Error)"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-div-1"
        },
        "style": {
          "type": "code",
          "value": "{fontWeight: \"bold\", fontSize: \"1.2rem\", padding: \"1rem 0\"}"
        }
      },
      "seq": 1,
      "void": false
    },
    {
      "from": null,
      "id": 2,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "ui form"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-div-2"
        }
      },
      "seq": 2,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 3,
      "name": "FormGroup",
      "parent": 2,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormGroup-3"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 3,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 4,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-4"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 4,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 5,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-5"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 5,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 6,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-6"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 6,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 7,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-7"
        },
        "inline": {
          "type": "code",
          "value": "true"
        }
      },
      "seq": 7,
      "void": false
    },
    {
      "from": null,
      "id": 8,
      "name": "label",
      "parent": 4,
      "props": {
        "children": {
          "type": "value",
          "value": "วันที่เกิดปัญหา"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-label-8"
        }
      },
      "seq": 8,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 9,
      "name": "DateTextBox",
      "parent": 5,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-DateTextBox-9"
        },
        "onChange": {
          "type": "code",
          "value": "(date)=>props.onChange(null,{name: \"start_date\", value: date})"
        },
        "value": {
          "type": "code",
          "value": "props.medErrorStatistics?.start_date"
        }
      },
      "seq": 9,
      "void": false
    },
    {
      "from": "react-lib/apps/common",
      "id": 10,
      "name": "DateTextBox",
      "parent": 7,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-DateTextBox-10"
        },
        "onChange": {
          "type": "code",
          "value": "(date)=>props.onChange(null,{name: \"end_date\", value: date})"
        },
        "value": {
          "type": "code",
          "value": "props.medErrorStatistics?.end_date"
        }
      },
      "seq": 10,
      "void": false
    },
    {
      "from": null,
      "id": 11,
      "name": "label",
      "parent": 6,
      "props": {
        "children": {
          "type": "value",
          "value": "ถึง"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-label-11"
        }
      },
      "seq": 11,
      "void": false
    },
    {
      "from": null,
      "id": 13,
      "name": "div",
      "parent": 3,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "className": {
          "type": "value",
          "value": "field"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-div-13"
        },
        "style": {
          "type": "code",
          "value": "{flex: 1}"
        }
      },
      "seq": 15,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 14,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-14"
        }
      },
      "seq": 16,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 15,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-15"
        },
        "width": {
          "type": "code",
          "value": "{minWidth: \"2rem\"}"
        }
      },
      "seq": 20,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 16,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-16"
        }
      },
      "seq": 27,
      "void": false
    },
    {
      "from": null,
      "id": 17,
      "name": "label",
      "parent": 14,
      "props": {
        "children": {
          "type": "value",
          "value": "จำนวน Error ทั้งหมด"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-label-17"
        }
      },
      "seq": 17,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 19,
      "name": "Button",
      "parent": 16,
      "props": {
        "children": {
          "type": "value",
          "value": "Export Excel"
        },
        "color": {
          "type": "value",
          "value": "brown"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-Button-19"
        },
        "onClick": {
          "type": "code",
          "value": "props.onExportExcel"
        }
      },
      "seq": 19,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 20,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-20"
        }
      },
      "seq": 13,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 23,
      "name": "Label",
      "parent": 15,
      "props": {
        "children": {
          "type": "code",
          "value": "props.number || \"0\""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-Label-23"
        },
        "size": {
          "type": "value",
          "value": "large"
        },
        "style": {
          "type": "code",
          "value": "{backgroundColor: \"transparent\", border: \"2px solid red\", borderRadius: \"3px\", color: \"red\"}"
        }
      },
      "seq": 23,
      "void": false
    },
    {
      "from": null,
      "id": 24,
      "name": "div",
      "parent": 0,
      "props": {
        "children": {
          "type": "value",
          "value": ""
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-div-24"
        }
      },
      "seq": 24,
      "void": false
    },
    {
      "from": "react-lib/frameworks/Table",
      "id": 25,
      "name": "Table",
      "parent": 24,
      "props": {
        "data": {
          "type": "code",
          "value": "props.items || []"
        },
        "headers": {
          "type": "value",
          "value": "ลำดับ,ผู้ที่ทำให้เกิดความผิดพลาด,ประเภท,รายการที่คลาดเคลื่อน,จำนวน"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-Table-25"
        },
        "keys": {
          "type": "value",
          "value": "no,stakeholder_name, type_name,drug_name,qty"
        },
        "showPagination": {
          "type": "code",
          "value": "false"
        },
        "style": {
          "type": "code",
          "value": "{height: \"calc(100vh - 15rem)\"}"
        },
        "widths": {
          "type": "value",
          "value": "150,auto,auto,auto,200"
        }
      },
      "seq": 25,
      "void": false
    },
    {
      "from": null,
      "id": 26,
      "name": "div",
      "parent": 20,
      "props": {
        "children": {
          "type": "code",
          "value": "props.ButtonSearch"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-div-26"
        }
      },
      "seq": 26,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 27,
      "name": "FormField",
      "parent": 3,
      "props": {
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-FormField-27"
        }
      },
      "seq": 14,
      "void": false
    },
    {
      "from": "semantic-ui-react",
      "id": 28,
      "name": "Button",
      "parent": 27,
      "props": {
        "children": {
          "type": "value",
          "value": "ย้อนกลับ"
        },
        "color": {
          "type": "value",
          "value": "yellow"
        },
        "id": {
          "type": "value",
          "value": "CardMedicationErrorStatisticsUX-Button-28"
        },
        "onClick": {
          "type": "code",
          "value": "props.onBack"
        }
      },
      "seq": 28,
      "void": false
    }
  ],
  "forwardRef": false,
  "height": 80,
  "isMounted": false,
  "memo": false,
  "name": "CardMedicationErrorStatisticsUX",
  "project": "IsHealth",
  "screenPropsDefault": {
    "userSearch": "[User Search Box]"
  },
  "width": 80
}

*********************************************************************************** */
